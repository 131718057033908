import React from 'react';
import './App.css';
import Metronome from './Metronome';
import Tuner from './Tuner';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
        <main>
          <Routes>
            <Route path="/" element={<Metronome />} />
            <Route path="/tuner" element={<Tuner />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
